import jwtDecode from "jwt-decode";
import {UserType} from "@/types/user-type";
import {addErrorClass} from "@/utils/js-html";
import {ValidationType} from "@/types/common-type";
import {store} from "@/store";

let refreshInProgress = false;
export const isJwtExpiredGoLogin = async ():Promise<void> => {
    if (!checkJwtExpired()) {
        if (!refreshInProgress) {
            refreshInProgress = true;
            await store.mutations.goLogin();
            refreshInProgress = false;
        }
    }
}

export const checkJwtExpired = (): boolean => {
    let checkResult = false;
    const token = localStorage.token;
    if (!token) {
        console.log("token not exists");
    } else {
        const cred: UserType = jwtDecode(token);

        if (cred && typeof cred.exp === "number") {
            const currentTime = new Date().getTime() / 1000;
            if (currentTime < cred.exp) {
                checkResult = true;
            } else {
                console.log("token expired");
            }
        }
    }
    return checkResult;
}

const isEmpty = (input: string | undefined): boolean => {
    return input == undefined || input === "";
}

const isPhone = (input: string): boolean => {
    return /^1[3456789]\d{9}$/.test(input);
}

const isEmail = (input: string): boolean => {
    const regex = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-z0-9]+(?:-[A-Za-z0-9]+)?\.)+[A-Za-z0-9]+(?:-[A-Za-z0-9]+)?$/;
    const temp = input.split("@");
    return regex.test(input) && temp[0].length <= 64 && temp[1].length <= 255;
}

const isEquals = (input1: string, input2: string): boolean => {
    return input1 === input2;
}

const checkEmpty = (str: string, classStr: string): string => {
    let msgCode = "";
    if (isEmpty(str)) {
        msgCode = "ITEM_EMPTY";
    }
    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkPhone = (phone: string): string => {
    let msgCode = "";
    if (isEmpty(phone)) {
        msgCode = "PHONE_EMPTY";
    } else if (!isPhone(phone)) {
        msgCode = "PHONE_INVALID";
    }
    if (msgCode != '')
        addErrorClass("phone");

    return msgCode;
}

const checkNotEmptyPhone = (phone: string): string => {
    let msgCode = "";
    if (phone && !isPhone(phone)) {
        msgCode = "PHONE_INVALID";
    }
    if (msgCode != '')
        addErrorClass("phone");

    return msgCode;
}

const checkName = (name: string | undefined, classStr="chinese-name"): string => {
    let msgCode = "";
    if (isEmpty(name) || isEmpty(name?.trim())) {
        msgCode = "NAME_EMPTY";
    }
    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkEmail = (email: string | undefined): string => {
    let msgCode = "";
    if (isEmpty(email)) {
        msgCode = "EMAIL_EMPTY";
    } else if (email && !isEmail(email)) {
        msgCode = "EMAIL_INVALID";
    }
    if (msgCode != '')
        addErrorClass("email");

    return msgCode;
}

const checkPassEmpty = (password: string | undefined, classStr="password"): string => {
    let msgCode = "";
    if (isEmpty(password)) {
        msgCode = "PASS_EMPTY";
    }
    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkPass = (password: string | undefined, classStr="password"): string => {

    //console.log( password + "  " + classStr + "   " + password?.length)
    let msgCode = "";
    if (isEmpty(password)) {
        msgCode = "PASS_EMPTY";
    } else if (password && (password.length < 6 || password.length > 20)) {
        msgCode = "PASS_LEN_ERR";
    }
    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkBirthday = (birthdate: string | undefined, classStr="birthdate"): string => {
    let msgCode = "";
    if (isEmpty(birthdate)) {
        msgCode = "BIRTHDATE_EMPTY";
    }
    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkIdentityCard = (id: string | undefined, classStr="id"): string => {
    let msgCode = "";
    const idRegLen = /^\d{14,17}[0-9Xx]$/;
    //const idReg18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    //const idReg15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
    // if (isEmpty(id)) {
    //     msgCode = "ID_EMPTY";
    // }
    if (id && !idRegLen.test(id)) {
        msgCode = "ID_LENGTH_15_18";
    }
    // else if (id && !idReg18.test(id) && !idReg15.test(id))
    //     msgCode = "ID_INVALID";

    if (msgCode != '')
        addErrorClass(classStr);

    return msgCode;
}

const checkPassEquals = (password: string, password2: string): string => {
    let msgCode = "";
    if (!isEquals(password, password2)) {
        msgCode = "PASS_NOT_MATCH";
    }
    if (msgCode != '') {
        addErrorClass("password-repeat");
    }

    return msgCode;
}

const checkValidateTO = (validationTO: ValidationType): boolean => {
    return validationTO.msgPhone === "" &&
        validationTO.msgEmail === "" &&
        validationTO.msgPass === "" &&
        validationTO.msgPass2 === "" &&
        validationTO.msgChineseName === "" &&
        validationTO.msgEnglishName === "" &&
        validationTO.msgChineseTitle === "" &&
        validationTO.msgEnglishTitle === "" &&
        validationTO.msgProvinceEnglish === "" &&
        validationTO.msgGender === "" &&
        validationTO.msgBirthday === "" &&
        validationTO.msgIdentityCard === "" &&
        validationTO.msgRole === "";
}

export const validateTool = {
    checkValidateTO,
    isEmpty,
    isPhone,
    isEmail,
    isEquals,
    checkEmpty,
    checkPhone,
    checkNotEmptyPhone,
    checkName,
    checkPass,
    checkPassEmpty,
    checkPassEquals,
    checkEmail,
    checkBirthday,
    checkIdentityCard
}