
export const initInputClass = (className = "mock"): void => {
    setTimeout(() => {
        const input = document.querySelectorAll('input[type="text"]') as NodeListOf<HTMLInputElement>;
        input.forEach(item => {
            if ("mock" === className && item.value)
                item.parentElement?.classList.add('active');
            else if (item.classList.contains(className))
                item.parentElement?.classList.add('active');
        });
    }, 100)
};

export const initPhoneClass = (className = "mock"): void => {
    setTimeout(() => {
        const input = document.querySelectorAll('input[type="tel"]') as NodeListOf<HTMLInputElement>;
        input.forEach(item => {
            if ("mock" === className && item.value)
                item.parentElement?.classList.add('active');
            else if (item.classList.contains(className))
                item.parentElement?.classList.add('active');
        });
    }, 100)
};

export const toggleInputClass = (): void => {
    const input = document.querySelectorAll('input');
    //console.log(input);
    input.forEach(item => {
        item.onfocus = () => {
            item.parentElement?.classList.add('active');
        }
        item.onblur = () => {
            if (item.value == null || item.value == '' && !item.classList.contains('datepicker')) {
                item.parentElement?.classList.remove('active');
            }
        }
        item.onchange = () => {
            //console.log("item.onchange")
            if (item.value && item.value != '') {
                item.parentElement?.classList.add('active');
            }
            if (item.parentElement?.classList.contains("error"))
                item.parentElement?.classList.remove('error');
        }
    });
};

export const toggleOverlay = (): void => {
    const overlay = document.querySelector('.overlay') as HTMLElement
    overlay.classList.toggle('active');
};

export const addErrorClass = (className: string): void => {
    const item = document.querySelector("." + className) as HTMLInputElement;
    if (!item.parentElement?.classList.contains("error"))
        item.parentElement?.classList.add('error');
};

export const showPassIcon = (): void => {
    const showPass = document.querySelectorAll('.showPassword');

    showPass.forEach((item) => {
        const image = item as HTMLImageElement;
        const passwordInput = image.previousElementSibling as HTMLInputElement;
        image.onclick = () => {
            if (passwordInput.type == 'password') {
                passwordInput.type = 'text';
            } else if (passwordInput.type == 'text') {
                passwordInput.type = 'password';
            }
        }
    })
};

export const customDropdown = (): void => {
    const dropdown = document.querySelectorAll('.dropdown');

    dropdown.forEach((item) => {
        const currentDiv = item.firstElementChild as HTMLDivElement;
        const arrowImage = item.querySelector('.arrow') as HTMLImageElement;
        const listDiv = item.lastElementChild as HTMLDivElement;

        currentDiv.onclick = () => {
            if(item.childElementCount==1) {
                return;
            }
            item.classList.toggle('active')
            listDiv.classList.toggle('active')

            listDiv.childNodes.forEach((option) => {
                const optionDiv = option as HTMLDivElement;
                optionDiv.onclick = () => {
                    item.classList.remove('active')
                    currentDiv.classList.add('filled')
                    listDiv.classList.remove('active')
                }
            })
        }
        arrowImage.onclick = currentDiv.onclick;
    })
};

export const windowOnScroll = (): void => {
    window.onscroll = function (){
        if (window.pageYOffset > 0) {
            document.querySelector('.fixed_header')?.classList.add('scrolled')
        } else {
            document.querySelector('.fixed_header')?.classList.remove('scrolled')
        }
    }


    if (document.body.clientHeight !== window.innerHeight) {
        document.querySelector('.desktop-wrapper')?.classList.add('scroll')
        document.body.style.cssText="--scroll-width: " + (window.innerWidth - document.body.offsetWidth) + "px;"
    }

    window.onresize = () => {
        document.body.style.cssText="--scroll-width: " + (window.innerWidth - document.body.offsetWidth) + "px;"
        if (document.body.clientHeight !== window.innerHeight) {
            document.querySelector('.desktop-wrapper')?.classList.add('scroll')
        } else {
            document.querySelector('.desktop-wrapper')?.classList.remove('scroll')
        }
    }

};

export const toggleItemCatalogue = (): void => {
    setTimeout(()=> {
        const cards = document.querySelectorAll('.card');
        cards.forEach((item) => {
            const card = item as HTMLElement;
            card.onclick = (e) => {
                if (e.target !== item.querySelector('.card-extend .item')
                    && e.target !== item.querySelector('.card-extend .count')
                    && e.target !== item.querySelector('.card-extend .title')) {
                    card.classList.toggle('active')
                }
            }
        })
    },1000)

};

export const mainPageFn = (): void => {
    const burger = document.querySelector('.burger') as HTMLElement;
    const menu = document.querySelector('.menu') as HTMLElement;
    const closeMenu  = document.querySelector('.close') as HTMLElement;

    burger.onclick = () => {
        menuToggle()
    }

    closeMenu.onclick = () => {
        menuToggle()
    }

    function menuToggle() {
        menu.classList.toggle('active')
        if (document.body.scrollHeight !== document.documentElement.offsetHeight) {
            menu.classList.add('scroll')
        }
    }
};


