import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app-div-font renewal",
  id: "app"
}
const _hoisted_2 = { class: "request_background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createElementVNode("section", {
      class: _normalizeClass(["change_message", { 'active': _ctx.store.getters.getMsg()!=='' }])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.store.getters.getMsg()), 1)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, _ctx.store.getters.getMaskLayer()]
    ])
  ]))
}