import { createI18n } from 'vue-i18n';
// https://lokalise.com/blog/vue-i18n/
// https://stackoverflow.com/questions/54476994/how-do-you-import-a-json-file-with-vuejs-typescript
import en from "@/locales/en.json"
import zh from "@/locales/zh.json"

export function getLanguage() : string {

  const userID = localStorage.user;
  const userLang = userID ? localStorage.getItem(userID + "_lang") : '';
  const appLang = localStorage.getItem("app_lang");
  const browserLanguage = navigator.language.includes('en') ? 'en' : 'zh';

  if (!userLang) {
    if (appLang) {
      return appLang;
    } else {
      return browserLanguage;
    }
  }
  return userLang;
}

export function getTitle() : string {

  const title = localStorage.getItem("title_" + getLanguage());
  if (title)
    document.title = title;

  return document.title;
}

export default createI18n({
  legacy: false,
  locale: getLanguage(),
  fallbackLocale: getLanguage(),
  messages: {
    en, zh
  }
})
