import { createApp } from 'vue';

import App from './App.vue'
import router from './router'
// https://vue-i18n.intlify.dev/guide/advanced/composition.html#global-scope-1
import i18n from './i18n'
import VCalendar from 'v-calendar';
// import naive from "naive-ui";
import jQuery from "jquery";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const $ = jQuery;

// https://v3.vuejs.org/guide/state-management.html#simple-state-management-from-scratch
createApp(App, {})
    .use(i18n)
    .use(router)
    // .use(naive)
    .use(VCalendar, {})
    .mount('#app');
