<template>
  <div class="app-div-font renewal" id="app">
    <router-view></router-view>
<!--    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" :key="$route.path"/>
      </transition>
    </router-view>-->
    <section class='change_message' :class="{ 'active': store.getters.getMsg()!=='' }">
      <p>{{ store.getters.getMsg() }}</p>
    </section>

    <div class="request_background" v-show="store.getters.getMaskLayer()"></div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import { windowOnScroll } from "@/utils/js-html";
import {store} from "@/store";

export default defineComponent({
  name: "app",
  components: {},
  setup() {
    onMounted(() => {
      windowOnScroll();
      localStorage.setItem("title_en", "Renewal");
      localStorage.setItem("title_zh", "日新");
    });

    return {
      store
    };
  }
});
</script>

<style lang="scss">
@import '@/_styles/APP.scss';
</style>
