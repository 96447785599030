import request from '@/utils/request'
import {TokenType, UpdatePwdTO, UserActivity, UserType} from "@/types/user-type";
import {checkJwtExpired, isJwtExpiredGoLogin} from "@/utils/validation-util";
import axios, {AxiosResponse} from "axios";
import {ResultTO} from "@/types/common-type";

async function signIn(user: UserType): Promise<AxiosResponse<TokenType>> {
    const link = "/v1/auth/sign-in";
    return request.post(link, {}, {
        withCredentials: false,
        auth: {
            username: user.phone,
            password: user.password ? user.password : ""
        }
    });
}

async function signUp(user: UserType, invitationCode: string): Promise<AxiosResponse<UserType>> {
    const link = `/v1/auth/public/sign-up?invitationCode=${invitationCode}`;
    return request.post(link, user);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function checkInvitationCode(invitationCode: string): Promise<AxiosResponse<any>> {
    const link = `/v1/auth/public/invitation-code/${invitationCode}`;
    return request.get(link);
}

async function logout(): Promise<AxiosResponse<ResultTO>> {
    const link = '/v1/auth/public/logout';
    return request.post(link);
}

async function resetPassword(validationCode: string, password: string): Promise<AxiosResponse<UserType>> {
    const link = "/v1/auth/public/reset-password";
    return request.post(link, {validationCode, password});
}

async function sendMail(emailAddress: string): Promise<AxiosResponse<string>> {
    const link = `/v1/auth/public/send-mail?email=${emailAddress}`;
    return request.get(link);
}

function isAuthenticated(): boolean {
    return checkJwtExpired();
}

async function checkToken():Promise<void> {
    await isJwtExpiredGoLogin();
}
/////////
const getUserProfile = async (userID: string): Promise<AxiosResponse<UserType>> => {
    const link = `/v1/user/${userID}`;
    return request.get(link);
}
const getUserActivity = async (userID: string): Promise<AxiosResponse<Array<UserActivity>>> => {
    const link = `/v1/user-activity/${userID}`;
    return request.get(link);
}

const getUserList = async (): Promise<AxiosResponse<Array<UserType>>> => {
    const link = '/v1/user/list';
    return request.get(link);
}

const updateUserProfile = async (user: UserType): Promise<AxiosResponse<UserType>> => {
    const link = '/v1/user/update';
    return request.put(link, user);
}

const disableUserProfile = async (userID: string): Promise<AxiosResponse<UserType>> => {
    const link = `/v1/user/disable/${userID}`;
    return request.put(link);
}

const updatePassword = async (user: UpdatePwdTO): Promise<AxiosResponse<UserType>> => {
    const link = '/v1/user/update-password';
    return request.put(link, user);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getShortURL = async (redirectUrl: string): Promise<any> => {
    // free API https://www.free-api.com/doc/300
    const url = "https://api.uomg.com/api/long2dwz?dwzapi=urlcn&url=" + redirectUrl;
    return axios.get(url);
}

const getInvitationCode = async (): Promise<AxiosResponse<ResultTO>> => {
    const link = '/v1/auth/invitation-code';
    return request.get(link);
}

export const userApi = {
    getUserActivity,
    getUserProfile,
    getUserList,
    updatePassword,
    updateUserProfile,
    disableUserProfile,
    //////////////
    getShortURL,
    getInvitationCode,
    checkInvitationCode,
    signIn,
    signUp,
    logout,
    resetPassword,
    sendMail,
    checkToken,
    isAuthenticated
}
