import request from '@/utils/request'
import {AxiosResponse} from "axios";
import {ItemGroup} from "@/types/item-type";
import {store} from "@/store";

const findAllItemGroupsAndSaveStore = async () => {
    const resp = await findAllItemGroups();
    if (resp.status === 200 && resp.data) {
        store.mutations.saveItemGroups(resp.data);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findAllItemGroupsAndSaveStoreWithCallback = async (callback: (() => any) | undefined) => {
    const resp = await findAllItemGroups();
    if (resp.status === 200 && resp.data) {
        store.mutations.saveItemGroups(resp.data);
        if (callback) callback();
    }
};

async function findAllItemGroups(): Promise<AxiosResponse<Array<ItemGroup>>> {
    const link = "/v1/item/all";
    return request.get(link);
}

async function saveItemGroup(itemGroup: ItemGroup): Promise<AxiosResponse<ItemGroup>> {
    const link = "/v1/item/saveItemGroup";
    return request.post(link, itemGroup);
}

export const itemApi = {
    findAllItemGroupsAndSaveStore,
    findAllItemGroupsAndSaveStoreWithCallback,
    findAllItemGroups,
    saveItemGroup
}