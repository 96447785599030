import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Layout from "@/views/Layout.vue";
import {userApi} from "@/apis/user-api";
import {store} from "@/store";
import {getTitle} from "@/i18n";

NProgress.configure({showSpinner: false})

const routes: Array<RouteRecordRaw> = [
    // home page
    {
        path: '/',
        name: 'Home Page',
        redirect: '/main/home',
    },
    // user section
    {
        path: '/user',
        redirect: '/user/sign-in',
        meta: {hidden: true, authenticated: false},
        component: Layout,
        children: [
            {
                path: 'sign-in',
                name: 'sign-in',
                component: () => import('@/views/user/sign-in.vue')
            }, {
                path: 'sign-up',
                name: 'sign-up',
                component: () => import('@/views/user/sign-up.vue')
            }, {
                path: 'forgot-password',
                name: 'forgot-password',
                component: () => import('@/views/user/forgot-password.vue')
            }, {
                path: 'password-recovery',
                name: 'password-recovery',
                component: () => import('@/views/user/password_recovery.vue')
            }, {
                path: 'profile',
                name: 'user-profile',
                meta: {authenticated: true},
                component: () => import('@/views/user/user-profile.vue')
            }, {
                path: 'list',
                name: 'user-list',
                meta: {authenticated: true, admin: true},
                component: () => import('@/views/user/user-list.vue')
            }, {
                path: 'edit',
                name: 'user-edit',
                meta: {authenticated: true},
                component: () => import('@/views/user/user-edit.vue')
            }
        ]
    },
    // main section
    {
        path: '/main',
        name: 'main',
        redirect: '/main/home',
        meta: {hidden: true, authenticated: true},
        component: Layout,
        children: [
            {
                path: 'home',
                name: 'home-page',
                component: () => import('@/views/main/main-page.vue')
            },
            {
                path: 'return',
                name: 'return-page',
                component: () => import('@/views/main/return-page.vue')
            },
            {
                path: 'give',
                name: 'give-page',
                component: () => import('@/views/main/give-page.vue')
            },
            {
                path: 'take',
                name: 'take-page',
                component: () => import('@/views/main/take-page.vue')
            }
        ]
    },
    // item section
    {
        path: '/item',
        name: 'item',
        redirect: '/item/item-catalogue',
        meta: {hidden: true, authenticated: true, admin: true},
        component: Layout,
        children: [
            {
                path: 'item-catalogue',
                name: 'item-catalogue',
                component: () => import('@/views/item/item-catalogue-list.vue')
            },
            {
                path: 'edit',
                name: 'edit-item',
                component: () => import('@/views/item/item.vue')
            },
            {
                path: 'edit-group',
                name: 'edit-item-group',
                component: () => import('@/views/item/item-group.vue')
            }
        ]
    },
    // guest section
    {
        path: '/guest',
        name: 'guest',
        redirect: '/guest/list',
        meta: { hidden: true, authenticated: true },
        component: Layout,
        children: [
            {
                path: 'start-service',
                name: 'start-service',
                component: () => import('@/views/guest/start-service.vue')
            },
            {
                path: 'list',
                name: 'guest-list',
                component: () => import('@/views/guest/guest-list.vue')
            },
            {
                path: 'edit',
                name: 'guest-edit',
                component: () => import('@/views/guest/guest-edit.vue')
            },
            {
                path: 'profile',
                name: 'guest-profile',
                component: () => import('@/views/guest/guest-profile.vue')
            }
        ]
    },
    // setting section
    {
        path: '/setting',
        name: 'setting',
        redirect: '/setting/settings',
        meta: { hidden: true, authenticated: true, admin: true },
        component: Layout,
        children: [
            {
                path: 'settings',
                name: 'settings',
                component: () => import('@/views/setting/settings.vue')
            },
            {
                path: 'language',
                name: 'setting-language',
                component: () => import('@/views/setting/language.vue')
            },
            {
                path: 'notification',
                name: 'setting-notification',
                component: () => import('@/views/setting/notification.vue')
            },
            {
                path: 'service',
                name: 'setting-service',
                component: () => import('@/views/setting/service-setting.vue')
            },
            {
                path: 'waiting-list',
                name: 'waiting-list',
                meta: { hidden: true, authenticated: false},
                component: () => import('@/views/setting/waiting-page.vue')
            }
        ]
    },
    // reports section
    {
        path: '/report',
        name: 'report',
        redirect: '/report/main',
        meta: { hidden: true, authenticated: true, admin: true },
        component: Layout,
        children: [
            {
                path: 'main',
                name: 'report-main',
                component: () => import('@/views/report/reports.vue')
            },
            {
                path: 'operation',
                name: 'report-operation',
                component: () => import('@/views/report/report-operation.vue')
            },
            {
                path: 'distribution',
                name: 'report-distribution',
                component: () => import('@/views/report/report-distribution.vue')
            },
            {
                path: 'behavior',
                name: 'report-behavior',
                component: () => import('@/views/report/report-behavior.vue')
            },{
                path: 'lost',
                name: 'report-lost',
                component: () => import('@/views/report/report-items-general.vue')
            },{
                path: 'unfulfilled',
                name: 'report-unfulfilled',
                component: () => import('@/views/report/report-items-general.vue')
            },{
                path: 'trashed',
                name: 'report-trashed',
                component: () => import('@/views/report/report-items-general.vue')
            },{
                path: 'distribution-history',
                name: 'report-distribution-history',
                component: () => import('@/views/report/report-distribution-by-guest.vue')
            },{
                path: 'guest-behavior',
                name: 'report-guest-behavior',
                component: () => import('@/views/report/report-behavior-by-guest.vue')
            },
        ]
    },
    //https://router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
    {
        path: '/:pathMatch(.*)*',
        // path: '/:pathMatch("((?!.*(js|css)).)*")', not working
        name: '404',
        redirect: '/user/sign-in'
    }

]

const router = createRouter({
    // history: createWebHashHistory(process.env.BASE_URL),
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(/*to, from, savedPosition*/) {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach((to, from) => {
    // Start progress bar
    NProgress.start();

    //console.warn("router.beforeEach -- " + to.path)
    // https://blog.csdn.net/maryword/article/details/107489069
    document.title = getTitle();

    let checkResult = true;
    if (to.meta.authenticated) {
        checkResult = userApi.isAuthenticated();
    }
    if (!checkResult) {
        console.info(to)
        console.info(from)
        store.mutations.goLogin(); // return "/user/sign-in";
    }

    return checkResult;
})

router.afterEach(() => {
    NProgress.done()

    // https://blog.csdn.net/maryword/article/details/107489069
    document.title = getTitle();

})

router.onError((error, to, from) => {
    console.warn("router.onError == " + error)
    console.warn("router.onError == " + to.path)
    console.warn("router.onError == " + from.path)
    NProgress.done()

    location.reload();
    // const r = window.confirm("Unexpected Error, do you agree to refresh the page?");
    // if (r){
    //     location.reload();
    // }
})


export default router
