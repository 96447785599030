import NProgress from 'nprogress'
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {showErrorMsg, showWarningMsg} from "@/utils/message-util";
import {baseURL, WarningMessages} from "@/const/const";

import i18n from '../i18n'
import {userApi} from "@/apis/user-api";
import {store} from "@/store";
import router from "@/router";

const { t } = i18n.global

NProgress.configure({showSpinner: true});

const request = axios.create({

    baseURL: baseURL,
    headers: {
        put: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    },
    withCredentials: true,
    timeout: 30000, // 30s for all axios request, nginx return 499 if above 30s
    // transformRequest: [(data) => {
    //     data = JSON.stringify(data)
    //     return data
    // }],
    // validateStatus() {
    //     return true
    // },
    transformResponse: [(data) => {
        if (typeof data === 'string' && (data.startsWith('{') || data.startsWith('['))) {
            return JSON.parse(data);
        }
        return data;
    }]
})

request.interceptors.request.use(async (config: AxiosRequestConfig) => {

    if (!config.url?.includes('/v1/service-record/all')) {
        NProgress.start();
        store.mutations.setMaskLayer(true);
    }

    if (!config.url?.includes('public') &&
        !config.url?.includes('sign')) {

        //console.log("request : " + config.url)
        await userApi.checkToken();

        const token = localStorage.token;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        } else {
            throw new axios.Cancel('Token is not available. Do login, please.');
            //return false;
        }
    }

    return config;
}, (error) => {
    NProgress.done();
    store.mutations.setMaskLayer(false);
    console.error(error)
    return Promise.resolve(error)
})

request.interceptors.response.use((response: AxiosResponse) => {
    NProgress.done();
    store.mutations.setMaskLayer(false);

    const status = response.status;
    if (status < 200 || status >= 300) {
        console.error("onFulfilled: " + response.data);
        showErrorMsg(t('message.' + response.data.message))
        throw response;
    }
    return response;
}, (error) => {
    NProgress.done();
    store.mutations.setMaskLayer(false);

    if (error.response?.data?.message) {
        console.error("onRejected: " + error.response?.data?.message)
    }
    if (error.response?.data?.msgCode) {

        if (WarningMessages.includes(error.response?.data?.msgCode))
            showWarningMsg(t('message.' + error.response.data.msgCode))
        else
            showErrorMsg(t('message.' + error.response.data.msgCode))

        if ('SERVICE_RECORD_NOT_EXISTS' === error.response.data.msgCode) {
            router.push({name: 'home-page'});
        } else if ('AUTHENTICATION_FAILED' === error.response.data.msgCode
            || 'JWT_EXPIRED' === error.response.data.msgCode) {
            router.push("/user/sign-in");
        }
    } else if (error instanceof axios.Cancel) {
        console.error(error)
    } else {
        console.error(error)
        showErrorMsg(t('message.General_Error'))
    }

    return Promise.resolve(error);
})

export default request