import { reactive } from "vue";
import {StateType, TokenType, UserType} from "@/types/user-type";
import {userTO} from "@/const/const";
import jwtDecode from "jwt-decode";
import {userApi} from "@/apis/user-api";
import {itemApi} from "@/apis/item-api";
import {ItemGroup} from "@/types/item-type";
import router from "@/router";
import {showInfoMsg} from "@/utils/message-util";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n, {getLanguage} from '../i18n'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { t, locale } = i18n.global

const state = reactive<StateType>({
    cred: {...userTO},
    message: "",
    maskLayer: false,
    confirmGoBack: false,
});

const mutations = {
    saveItemGroups(itemGroups: ItemGroup[]):void {
        state.itemGroups=itemGroups
    },
    async saveCred(token:string) {
        const cred: UserType = jwtDecode(token);
        const loginResp = await userApi.getUserProfile(cred.id);
        state.cred = loginResp.data;
        localStorage.user = loginResp.data.phone;
        if (loginResp.data.language) {
            localStorage.setItem(loginResp.data.phone + "_lang", loginResp.data.language);
            localStorage.setItem("app_lang", loginResp.data.language);
        }
    },
    login(token: TokenType):void {
        if (token.token && token.refresh_token) {
            localStorage.token = token.token;
            localStorage.refresh_token = token.refresh_token;
            mutations.saveCred(token.token);
        }
    },
    async logout() {
        await router.push("/user/sign-in");
        if (localStorage.token) {
            const resp = await userApi.logout();
            if (resp.status === 200 && resp.data) {
                showInfoMsg(t("message.LOGOUT_SUCCESS"))
            }
            localStorage.user = "";
            localStorage.token = "";
            localStorage.refresh_token = "";
        }
        state.cred = {...userTO};
        // locale = getLanguage(); // TODO
    },
    async goLogin() {
        userApi.logout()
        localStorage.user = "";
        localStorage.token = "";
        localStorage.refresh_token = "";
        state.cred = {...userTO};
        location.href = '/app/user/sign-in'
        //setTimeout(()=> location.reload(), 300);
    },
    showMsg(msg:string):void {
        state.message = msg;
        setTimeout(()=> state.message='', 5000);
    },
    // 模态层 https://blog.csdn.net/qing_gee/article/details/52083995
    setMaskLayer(mask: boolean):void {
        state.maskLayer = mask;
        setTimeout(()=> state.maskLayer=false, 5000);
    },
    setConfirmGoBack(flag: boolean):void {
        state.confirmGoBack = flag;
        setTimeout(()=> state.confirmGoBack=false, 2000);
    },
};

const getters = {
    getMsg() {
        return state.message;
    },
    getMaskLayer() {
        return state.maskLayer;
    },
    getConfirmGoBack() {
        return state.confirmGoBack;
    },
    getLoginUserID() {
        if (localStorage.token && userApi.isAuthenticated()) {
            const cred: UserType = jwtDecode(localStorage.token);
            return cred.id;
        }
        return "";
    },
    getCred() {
        if (state.cred && state.cred.phone === "" && userApi.isAuthenticated()) {
            // user refresh the page
            mutations.saveCred(localStorage.token);
        }
        return state.cred;
    },
    getItemGroups() {
        if (!state.itemGroups)
            itemApi.findAllItemGroupsAndSaveStore();
        return state.itemGroups;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getItemGroupsWithCallback(callback: () => any) {
        if (!state.itemGroups)
            itemApi.findAllItemGroupsAndSaveStoreWithCallback(callback);
        else if (callback)
            callback();
        return state.itemGroups;
    }
};

export const store = {
    // state: readonly(state),
    mutations,
    getters,
};