import {Capacity, ValidationType} from "@/types/common-type";
import { UserType} from "@/types/user-type";
import { PolicyType, Item, ItemGroup } from "@/types/item-type";
import {Guest, GuestAdditional, Laundry, Review} from "@/types/service-type";
import {LaundryStatus} from "@/types/enums";
import {DailySummaryTO, TemperatureTO} from "@/types/report-type";

export const baseURL: string = process.env.NODE_ENV === 'production' ? '/api' : '/proxy';

export const WarningMessages = [
    "IN_SERVICE_LIST_FULL",
    "GUEST_ALREADY_ADDED"
]

export const userTO: UserType = {
    id: "",
    phone: "",
    password: "",
    email: "",
    chineseName: "",
    englishName: "",
    role: "",
}

export const guestTO: Guest = {
    id: "",
    phone: "",
    gender: "",
    identityCard: "",
    chineseName: "",
    englishName: "",
    avatarId: "",
    provinceChinese: "",
    provinceEnglish: "",
    birthDate: "",
    lastVisitDate: "",
    facialAbility: false
}

export const guestAdditionalTO: GuestAdditional = {
    avgWaitingTime: 0,
    avgServiceTime: 0,
    badReviewCount: 0,
    goodReviewCount: 0,
    serviceStatus: undefined,
    totalVisit: 0,
    totalVisitCurrYear: 0
}

export const laundryTO: Laundry = {
    id: "",
    tag: 0,
    guest: {...guestTO},
    newTagCycle: true,
    status: LaundryStatus.taken,
    statusChangeTime: "",
    laundryItems: []
}

export const itemTO: Item = {
    id: "",
    groupId: "",
    englishTitle: "",
    chineseTitle: "",
    washable: true,
    active: true,
    popularFlag: false,
    ranking: 0,
}

export const itemGroupTO: ItemGroup = {
    id: "",
    englishTitle: "",
    chineseTitle: "",
    iconKey: "",
    policy: -1,
    washable: true,
    active: true,
    popularFlag: false,
    ranking: 0,
    items: [],
}

export const validationTO: ValidationType = {
    msgPhone: "",
    msgEmail: "",
    msgPass: "",
    msgPass2: "",
    msgChineseName: "",
    msgEnglishName: "",
    msgRole: "",
    msgChineseTitle: "",
    msgEnglishTitle: "",
    msgProvinceEnglish: "",
    msgGender: "",
    msgBirthday: "",
    msgIdentityCard: ""
}


export const summaryTO: DailySummaryTO = {
    statisticDate: "",
    totalVisit: 0,
    mealsServed: 0,
    avgWaitingTime: 0,
    avgServiceTime: 0,
    itemDistributed: 0,
    newGuests: 0,
    totalIndividuals: 0,
    laundryTaken: 0
}

export const temperatureTO: TemperatureTO = {
    serviceDate: "",
    chineseName: "",
    englishName: "",
    temperature: 0,
    avatarId: ""
}

export const capacityTO: Capacity = {
    id: "",
    serviceCapacity: 0,
    serviceDuration: 0,
    waitingDuration: 0
}

export const reviewTO: Review = {
    id: "",
    guest: {...guestTO},
    user: {...userTO},
    greenFace: true,
    comment: "",
    deleted: false,
    showMore: false,
    showMenu: false
}

// export const policyListTO: PolicyType[] = [{
//     value: 1,
//     name: "1 day"
// },{
//     value: 2,
//     name: "2 days"
// },{
//     value: 3,
//     name: "3 days"
// }
// ];
//
// export const policyListZhTO: PolicyType[] = [{
//     value: 1,
//     name: "1 天"
// },{
//     value: 2,
//     name: "2 天"
// },{
//     value: 3,
//     name: "3 天"
// }
// ];
//
export const policyListTO: PolicyType[] = [{
    value: 7,
    name: "7 days"
},{
    value: 14,
    name: "14 days"
},{
    value: 30,
    name: "30 days"
},{
    value: 60,
    name: "60 days"
}
];
export const policyListZhTO: PolicyType[] = [{
    value: 7,
    name: "7 天"
},{
    value: 14,
    name: "14 天"
},{
    value: 30,
    name: "30 天"
},{
    value: 60,
    name: "60 天"
}
];

