

export const ServiceStatus = {
    waiting: 'Waiting',
    inService: 'InService',
    finished: 'Finished'
}

export const GenderType = {
    male: 'MALE',
    female: 'FEMALE'
}

export const LaundryStatus = {
    taken: 'TAKEN',
    returned: 'RETURNED'
}

export const UserRole = {
    admin: "ADMIN",
    volunteer: "VOLUNTEER"
}

export const ReportType = {
    All: "All",
    Lost: "Lost",
    Trashed: "Trashed",
    Unfulfilled: "Unfulfilled",
    DistributionHistory: "DistributionHistory"
}

export const GiveType = {
    Gave: "Gave",
    Lost: "Lost",
    Trash: "Trash"
}

export const ActivationStatus = {
    Active: "ACTIVE",
    Inactive: "INACTIVE"
}