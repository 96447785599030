// https://szboynono.github.io/mosha-vue-toastify/getting-started/installation.html
// https://maronato.github.io/vue-toastification/

// import the library
import { createToast } from 'mosha-vue-toastify';
import { ToastType } from "mosha-vue-toastify/dist/types";

// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import '@/_styles/lib/mosha-vue-toastify.scss'

//   original black design      showCommonMessage();

export const showDefaultMsg = (message: string, seconds= 0): void => {
    showMsg(message, "default", seconds);
}

export const showSuccessMsg = (message: string, seconds= 0): void => {
    showMsg(message, "success", seconds);
}

export const showInfoMsg = (message: string, seconds= 0): void => {
    showMsg(message, "info", seconds);
}

export const showWarningMsg = (message: string, seconds= 0): void => {
    showMsg(message, "warning", seconds);
}

export const showErrorMsg = (message: string, seconds= 0): void => {
    showMsg(message, "danger", seconds);
}

let onlyOneMessageFlag = false;
const showMsg = (message: string, level: ToastType, seconds= 0): void => {
    if (onlyOneMessageFlag)
        return;

    onlyOneMessageFlag = true;

    const msgTimeout = seconds ===0 ? 3000 : seconds*1000;
    createToast(message,
        {
            type: level,
            position: 'top-center',
            timeout: msgTimeout,
            showIcon: true,
            transition: 'bounce',
            swipeClose: true
        })

    setTimeout(() => {
        onlyOneMessageFlag = false;
    }, 1000)
}